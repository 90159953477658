<template>
  <cw-page
    icon="check"
    class="car-loan__complete"
  >
    <v-row>
      <v-col>
        <p class="mb-0">
          <translate>
            You can now close your browser.
          </translate>
        </p>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>

export default {
  name: 'CwCompleteCarLoan',
};
</script>
